import React, { FC } from 'react';
import { ITabs } from './Tabs.def';

const Tabs: FC<ITabs> = ({ tabs, activeTabId, setActiveTabId }) => {
  return (
    <div className="tabs">
      <div className="container">
        {tabs.map((tab) => (
          <div
            onClick={() => setActiveTabId(tab.id)}
            className={`tab ${activeTabId === tab.id ? 'tab--active' : ''}`}
            key={tab.id}
          >
            <span className="tab__name">{tab.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;

import React from 'react';
import { graphql, PageProps } from 'gatsby';

import { getArticles, getDefaultSEO, getFeaturedImageDataFromWpPost } from '../../utils';
import Seo from '../../components/Seo';
import {
  HT_DEFAULT_PAGE_NAME,
  HT_PAGE_CATEGORY_ARCHIVE_PAGE,
  MAX_NUMBER_OF_POSTS_PER_PAGE,
  TRAVEL_PRODUCTS_REVIEWS_CATEGORIES,
} from '../../constants';
import TravelProductReviews from '../../components/Travel/components/TravelProductReviews/TravelProductReviews';
import HTPageWrapper from '../../wrappers/HTPageWrapper';

interface ITravelProductReviewsTemplateData {
  posts: Queries.WpPostConnection;
}

const TravelProductReviewsTemplate = ({
  data,
  pageContext,
  location,
}: PageProps<ITravelProductReviewsTemplateData>) => {
  const {
    posts: { nodes },
  } = data;
  const currentPageIndex = (pageContext as any)?.pageNumber - 1 || 0;
  let posts: Queries.WpPost[] = [];

  if (nodes && Array.isArray(nodes)) {
    posts = nodes;
  }

  const articles = getArticles(posts)
    .map((article) => ({
      ...article,
      imageOverlay: true,
    }))
    .map((article) => {
      const category = TRAVEL_PRODUCTS_REVIEWS_CATEGORIES.find((category) => {
        return (
          category.possibleValues.find((value) =>
            article.title.toLowerCase().includes(value.toLowerCase()),
          ) || false
        );
      });
      if (category) {
        return {
          ...article,
          category: {
            link: false,
            text: category.text,
          },
        };
      }
      return {
        ...article,
        category: {
          text: 'Travel Accessories',
          link: false,
        },
      };
    });

  const totalNumberOfPages = (pageContext as any)?.totalNumberOfPages || 0;
  const title = 'Travel Product Reviews';

  return (
    <HTPageWrapper
      category={"Travel, Reviews, " + HT_PAGE_CATEGORY_ARCHIVE_PAGE}
      title={title || HT_DEFAULT_PAGE_NAME}
      location={location}
    >
      <TravelProductReviews
        pageHeader={{
          title: title,
          breadCrumbs: [
            {
              link: '/',
              text: 'Home',
            },
            {
              link: '/travel/',
              text: 'Travel',
            },
            {
              link: '/travel/product-reviews/',
              text: 'Travel Product Reviews',
            },
          ],
          subTitle:
            'Whether you’re looking for the most secure luggage or the best headphones to keep you entertained on your next flight, our product reviews are the best place to start.',
        }}
        productReviews={articles}
        pagination={{
          currentPageIndex,
          pageSize: MAX_NUMBER_OF_POSTS_PER_PAGE,
          basePath: '/travel/product-reviews',
          numberOfPages: totalNumberOfPages,
        }}
      />
    </HTPageWrapper>
  );
};

export default TravelProductReviewsTemplate;

export const Head = ({ location, data }: PageProps<ITravelProductReviewsTemplateData>) => {
  const pageTitle = 'Travel Product Reviews | UpgradedPoints.com';
  const socialTitle = pageTitle;
  const ogDescription =
    'Explore 100s of expert reviews to make sure you everything you need for your next trip.';
  const includeCollectionPageType = true;
  let pathname = location.pathname;
  if (!pathname.endsWith('/')) pathname += '/';
  const pageNumber = pathname.includes('page') ? parseInt(pathname.slice(-2, -1)) : 1;
  const extraBreadcrumbList = [
    { name: 'Travel', slug: 'travel' },
    { name: 'Travel Product Reviews', slug: 'product-reviews' },
  ];
  if (pageNumber > 1) {
    extraBreadcrumbList.push({
      name: 'Travel Product Reviews - Page ' + pageNumber,
      slug: 'page/' + pageNumber,
    });
  }
  const seo = getDefaultSEO(
    location.pathname,
    pageTitle,
    socialTitle,
    ogDescription,
    includeCollectionPageType,
    extraBreadcrumbList,
  );
  const firstPost = data?.posts?.nodes[0];
  const featuredImageData = firstPost ? getFeaturedImageDataFromWpPost(firstPost) : null;
  return (
    <Seo
      title={pageTitle}
      wpSeo={seo}
      featuredImageData={featuredImageData}
      pageNumber={pageNumber}
    />
  );
};

export const query = graphql`
  query TravelProductReviews($start: Int, $tagName: String) {
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { name: { regex: $tagName } } } } }
      limit: 21
      skip: $start
      sort: { date: DESC }
    ) {
      nodes {
        title
        uri
        featuredImage {
          node {
            caption
            width
            height
            sourceUrl
          }
        }
      }
    }
  }
`;

import React, { FC, useState } from 'react';
import { ITravelProductReviews } from './TravelProductReviews.def';
import PageHeader from '../../../PageHeader/PageHeader';
import PaginationV2 from '../../../PaginationV2/PaginationV2';
import SquareBrowser from '../../../SquareBrowser/SquareBrowser';
import Tabs from '../../../Tabs/Tabs';

const TravelProductReviews: FC<ITravelProductReviews> = ({
  pageHeader,
  pagination,
  productReviews,
}) => {
  const [selectedCategoryId, setSelectedCategoryId] = useState('');

  const uniqueCategories: string[] = ['All Reviews'];

  const categories = uniqueCategories.map((category) => ({
    id: category,
    name: category,
  }));

  const firstCategoryId = categories[0]?.id as string;

  const filteredProductReviews =
    selectedCategoryId && selectedCategoryId !== firstCategoryId
      ? productReviews.filter(
          (productReview) => productReview.category?.text?.toLowerCase() === selectedCategoryId
        )
      : productReviews;

  return (
    <div className="travel-product-reviews">
      <PageHeader {...pageHeader} />
      <Tabs
        tabs={categories}
        activeTabId={selectedCategoryId || firstCategoryId}
        setActiveTabId={setSelectedCategoryId}
      />
      <div className="container">
        <SquareBrowser articles={filteredProductReviews} />
        <PaginationV2 {...pagination} />
      </div>
    </div>
  );
};

export default TravelProductReviews;
